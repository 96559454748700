@use "variables";

@use "sass:math";
@use "sass:map";

.providers {
  .provider {
    cursor: default;
    display: block;
    .fa-check {
      color: #8bc34a;
    }
    .fa-plus {
      color: #00BCD4;
    }
    span, a {
      padding-left: 8px;
    }
    a:link {
      text-decoration: underline;
    }

    @media screen and (min-width: (map.get(variables.$breakpoints, "small") + "px")) {
      width: math.div(100%, 2);
    }

    @media screen and (min-width: (map.get(variables.$breakpoints, "large") + "px")) {
      width: math.div(100%, 3);
    }

    @media screen and (min-width: (map.get(variables.$breakpoints, "xlarge") + "px")) {
      width: math.div(100%, 4);
    }
  }
}
