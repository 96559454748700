@use "variables";

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  line-height: 2rem;
}

body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a,
button {
  color: inherit;
  transition: variables.$transition-duration;
}

a {
  text-decoration: none;
}

button {
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}

:focus {
  outline: 0;
};

img {
  width: 100%;
  max-width: 100%;

  // ie 11 bug fix
  min-height: 2px;
}

button,
input {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}
