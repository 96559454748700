#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  z-index: 1031;
  background: #29d;
  width: 100%;
  height: 2px;
  position: fixed;
  top: 0;
  left: 0;
}

#nprogress .peg {
  opacity: 1;
  width: 100px;
  height: 100%;
  display: block;
  position: absolute;
  right: 0;
  transform: rotate(3deg)translate(0, -4px);
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
}

#nprogress .spinner {
  z-index: 1031;
  display: block;
  position: fixed;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  box-sizing: border-box;
  border: 2px solid #0000;
  border-color: #29d #0000 #0000 #29d;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: .4s linear infinite nprogress-spinner;
}

.nprogress-custom-parent {
  position: relative;
  overflow: hidden;
}

.nprogress-custom-parent #nprogress .spinner, .nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

[class*="hint--"] {
  display: inline-block;
  position: relative;
}

[class*="hint--"]:after, [class*="hint--"]:before {
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  transition: all .3s;
  position: absolute;
  transform: translate3d(0, 0, 0);
}

[class*="hint--"]:hover:after, [class*="hint--"]:hover:before {
  visibility: visible;
  opacity: 1;
  transition-delay: .1s;
}

[class*="hint--"]:before {
  content: "";
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  z-index: 1000001;
  background: #383838;
  border: 6px solid #0000;
  position: absolute;
}

[class*="hint--"]:after {
  color: #fff;
  white-space: nowrap;
  text-shadow: 0 1px #000;
  background: #383838;
  padding: 8px 10px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  box-shadow: 4px 4px 8px #0000004d;
}

.hint--error:after, .hint--error:before {
  background-color: #b24e4c;
}

[class*="hint--"][aria-label]:after {
  content: attr(aria-label);
}

[class*="hint--"][data-hint]:after {
  content: attr(data-hint);
}

[aria-label=""]:after, [aria-label=""]:before, [data-hint=""]:after, [data-hint=""]:before {
  display: none !important;
}

.hint--top {
  --rotation: 135deg;
}

.hint--top:after, .hint--top:before {
  bottom: 100%;
  left: 50%;
}

.hint--top:before {
  transform: rotate(var(--rotation));
  margin-bottom: -5.5px;
  left: calc(50% - 6px);
}

.hint--top:after {
  transform: translateX(-50%);
}

.hint--top:hover:before {
  transform: translateY(-8px) rotate(var(--rotation));
}

.hint--top:hover:after {
  transform: translateX(-50%)translateY(-8px);
}

.hint--bottom {
  --rotation: -45deg;
}

.hint--bottom:after, .hint--bottom:before {
  top: 100%;
  left: 50%;
}

.hint--bottom:before {
  transform: rotate(var(--rotation));
  margin-top: -5.5px;
  left: calc(50% - 6px);
}

.hint--bottom:after {
  transform: translateX(-50%);
}

.hint--bottom:hover:before {
  transform: translateY(8px) rotate(var(--rotation));
}

.hint--bottom:hover:after {
  transform: translateX(-50%)translateY(8px);
}

.hint--right {
  --rotation: -135deg;
}

.hint--right:before {
  transform: rotate(var(--rotation));
  margin-bottom: -6px;
  margin-left: -5.5px;
}

.hint--right:after {
  margin-bottom: calc(-.5rem - 8px);
}

.hint--right:after, .hint--right:before {
  bottom: 50%;
  left: 100%;
}

.hint--right:hover:before {
  transform: translateX(8px) rotate(var(--rotation));
}

.hint--right:hover:after {
  transform: translateX(8px);
}

.hint--left {
  --rotation: 45deg;
}

.hint--left:before {
  transform: rotate(var(--rotation));
  margin-bottom: -6px;
  margin-right: -5.5px;
}

.hint--left:after {
  margin-bottom: calc(-.5rem - 8px);
}

.hint--left:after, .hint--left:before {
  bottom: 50%;
  right: 100%;
}

.hint--left:hover:before {
  transform: translateX(-8px) rotate(var(--rotation));
}

.hint--left:hover:after {
  transform: translateX(-8px);
}

.hint--top-left {
  --rotation: 135deg;
}

.hint--top-left:after, .hint--top-left:before {
  bottom: 100%;
  left: 50%;
}

.hint--top-left:before {
  transform: rotate(var(--rotation));
  margin-bottom: -5.5px;
  left: calc(50% - 6px);
}

.hint--top-left:after {
  margin-left: 12px;
  transform: translateX(-100%);
}

.hint--top-left:hover:before {
  transform: translateY(-8px) rotate(var(--rotation));
}

.hint--top-left:hover:after {
  transform: translateX(-100%)translateY(-8px);
}

.hint--top-right {
  --rotation: 135deg;
}

.hint--top-right:after, .hint--top-right:before {
  bottom: 100%;
  left: 50%;
}

.hint--top-right:before {
  transform: rotate(var(--rotation));
  margin-bottom: -5.5px;
  left: calc(50% - 6px);
}

.hint--top-right:after {
  margin-left: -12px;
  transform: translateX(0);
}

.hint--top-right:hover:before {
  transform: translateY(-8px) rotate(var(--rotation));
}

.hint--top-right:hover:after {
  transform: translateY(-8px);
}

.hint--bottom-left {
  --rotation: -45deg;
}

.hint--bottom-left:after, .hint--bottom-left:before {
  top: 100%;
  left: 50%;
}

.hint--bottom-left:before {
  transform: rotate(var(--rotation));
  margin-top: -5.5px;
  left: calc(50% - 6px);
}

.hint--bottom-left:after {
  margin-left: 12px;
  transform: translateX(-100%);
}

.hint--bottom-left:hover:before {
  transform: translateY(8px) rotate(var(--rotation));
}

.hint--bottom-left:hover:after {
  transform: translateX(-100%)translateY(8px);
}

.hint--bottom-right {
  --rotation: -45deg;
}

.hint--bottom-right:after, .hint--bottom-right:before {
  top: 100%;
  left: 50%;
}

.hint--bottom-right:before {
  transform: rotate(var(--rotation));
  margin-top: -5.5px;
  left: calc(50% - 6px);
}

.hint--bottom-right:after {
  margin-left: -12px;
  transform: translateX(0);
}

.hint--bottom-right:hover:before {
  transform: translateY(8px) rotate(var(--rotation));
}

.hint--bottom-right:hover:after {
  transform: translateY(8px);
}

.hint--fit:after, .hint--large:after, .hint--medium:after, .hint--small:after {
  box-sizing: border-box;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.4em;
}

.hint--small:after {
  width: 80px;
}

.hint--medium:after {
  width: 150px;
}

.hint--large:after {
  width: 300px;
}

.hint--fit:after {
  width: 100%;
}

.hint--error:after {
  text-shadow: 0 1px #592726;
}

.hint--warning:after, .hint--warning:before {
  background-color: #bf9853;
}

.hint--warning:after {
  text-shadow: 0 1px #6c5328;
}

.hint--info:after, .hint--info:before {
  background-color: #3985ac;
}

.hint--info:after {
  text-shadow: 0 1px #1a3c4d;
}

.hint--success:after, .hint--success:before {
  background-color: #458646;
}

.hint--success:after {
  text-shadow: 0 1px #1a321a;
}

.hint--always:after, .hint--always:before {
  opacity: 1;
  visibility: visible;
}

.hint--always.hint--top:before {
  transform: translateY(-8px) rotate(var(--rotation));
}

.hint--always.hint--top:after {
  transform: translateX(-50%)translateY(-8px);
}

.hint--always.hint--top-left:before {
  transform: translateY(-8px) rotate(var(--rotation));
}

.hint--always.hint--top-left:after {
  transform: translateX(-100%)translateY(-8px);
}

.hint--always.hint--top-right:before {
  transform: translateY(-8px) rotate(var(--rotation));
}

.hint--always.hint--top-right:after {
  transform: translateY(-8px);
}

.hint--always.hint--bottom:before {
  transform: translateY(8px) rotate(var(--rotation));
}

.hint--always.hint--bottom:after {
  transform: translateX(-50%)translateY(8px);
}

.hint--always.hint--bottom-left:before {
  transform: translateY(8px) rotate(var(--rotation));
}

.hint--always.hint--bottom-left:after {
  transform: translateX(-100%)translateY(8px);
}

.hint--always.hint--bottom-right:before {
  transform: translateY(8px) rotate(var(--rotation));
}

.hint--always.hint--bottom-right:after {
  transform: translateY(8px);
}

.hint--always.hint--left:before {
  transform: translateX(-8px) rotate(var(--rotation));
}

.hint--always.hint--left:after {
  transform: translateX(-8px);
}

.hint--always.hint--right:before {
  transform: translateX(8px) rotate(var(--rotation));
}

.hint--always.hint--right:after {
  transform: translateX(8px);
}

.hint--rounded:before {
  border-radius: 0 4px 0 0;
}

.hint--rounded:after {
  border-radius: 4px;
}

.hint--no-animate:after, .hint--no-animate:before {
  transition-duration: 0s;
}

.hint--bounce:after, .hint--bounce:before {
  transition: opacity .3s, visibility .3s, transform .3s cubic-bezier(.71, 1.7, .77, 1.24);
}

@supports (transition-timing-function: linear(0, 1)) {
  .hint--bounce:after, .hint--bounce:before {
    --spring-easing: linear(0, .009, .035 2.1%, .141 4.4%, .723 12.9%, .938, 1.077 20.4%, 1.121, 1.149 24.3%, 1.159, 1.163 27%, 1.154, 1.129 32.8%, 1.051 39.6%, 1.017 43.1%, .991, .977 51%, .975 57.1%, .997 69.8%, 1.003 76.9%, 1);
    transition: opacity .3s ease, visibility .3s ease, transform .5s var(--spring-easing);
  }
}

.hint--no-shadow:after, .hint--no-shadow:before {
  text-shadow: initial;
  box-shadow: initial;
}

.hint--no-arrow:before {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 16px;
  line-height: 2rem;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  color: inherit;
  transition: all .5s;
}

a {
  text-decoration: none;
}

button {
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  overflow: visible;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

img {
  width: 100%;
  max-width: 100%;
  min-height: 2px;
}

button, input {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

html {
  color: #37474f;
  scroll-behavior: smooth;
  background: #f5f5f5;
  font-family: Montserrat, sans-serif;
}

.dark-bg {
  color: #fff;
  background-color: #42474b;
}

header h1.title {
  letter-spacing: 1px;
  margin: .5em 0 .25em;
  font-size: 1.5em;
}

header h1.title a {
  color: #fff;
}

header h1.title strong {
  color: #2d3130;
  text-shadow: 1px 1px #ffffff4d;
  margin-right: 6px;
  font-size: 1.1em;
}

footer {
  text-align: right;
  margin-bottom: 15px;
  font-size: 15px;
}

footer a {
  color: #607d8b;
  margin-left: 12px;
}

footer a > i {
  margin: 0 2px;
}

.row {
  flex-wrap: wrap;
  flex: 1 0 auto;
  padding: 2em 0;
  display: flex;
}

@media screen and (width >= 1024px) {
  .row {
    margin: 0 -15px;
  }
}

.row > * {
  width: 100%;
}

@media screen and (width >= 1024px) {
  .row > * {
    padding: 0 15px;
  }
}

.container {
  padding: 1rem 1.5rem;
  position: relative;
}

@media screen and (width >= 1024px) {
  .container {
    padding: 1rem calc(1.5rem + 50vw - 480px);
  }
}

@media screen and (width >= 1280px) {
  .container {
    padding: 1rem calc(1.5rem + 50vw - 576px);
  }
}

@media screen and (width >= 1440px) {
  .container {
    padding: 1rem calc(1.5rem + 50vw - 672px);
  }
}

.container > section {
  opacity: 1;
  border-top: 1px solid #dcdcdc;
  padding-top: 2em;
  padding-bottom: 3em;
  transition: opacity .5s;
}

.container > section:first-child {
  border-top: none;
}

.container > section.hidden {
  opacity: 0;
  max-height: 0;
  padding: 0;
}

.button, .input {
  border-radius: .25rem;
}

.button:focus, .input:focus {
  outline: none;
}

.input {
  border: 1px solid #0000;
  padding: .6rem;
  font-family: Verdana;
  font-size: 15px;
  font-weight: 200;
}

.button {
  letter-spacing: 1px;
  text-align: center;
  background-color: #00000020;
  border: 1px solid #00000020;
  padding: .6rem 2rem;
  transition: box-shadow .5s;
  box-shadow: 0 0 #0000;
}

.button:focus {
  outline: none;
  box-shadow: 0 0 4px #000000b3;
}

code[class*="language-"], code.language, pre[class*="language-"], pre.language {
  color: #000;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
  background: none;
  line-height: 1.5;
}

pre[class*="language-"], pre.language {
  margin: .5em 0;
  padding: 0;
  position: relative;
  overflow: visible;
}

pre[class*="language-"] > code, pre.language > code {
  background-color: #fdfdfd;
  border-left: 10px solid #90a4ae;
  position: relative;
  box-shadow: -1px 0 #90a4ae, 0 0 0 1px #dfdfdf;
}

code[class*="language"], code.language {
  max-height: inherit;
  height: inherit;
  border-radius: .3em;
  padding: .5em 1em;
  display: block;
  overflow: auto;
}

:not(pre):not(pre.language) > code[class*="language-"], :not(pre):not(pre.language) > code.language, pre[class*="language-"], pre.language {
  box-sizing: border-box;
  background-color: #fdfdfd;
  margin-bottom: 1em;
}

:not(pre):not(pre.language) > code[class*="language-"], :not(pre):not(pre.language) > code.language {
  color: #c92c2c;
  white-space: normal;
  border: 1px solid #0000001a;
  border-radius: .3em;
  padding: .2em;
  display: inline;
  position: relative;
}

.token.comment, .token.block-comment, .token.prolog, .token.doctype, .token.cdata {
  color: #7d8b99;
}

.token.punctuation {
  color: #607d8b;
}

.token.property, .token.tag, .token.boolean, .token.number, .token.function-name, .token.constant, .token.symbol, .token.deleted {
  color: #e06c75;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.function, .token.builtin, .token.inserted {
  color: #d19a66;
}

.token.operator, .token.entity, .token.url, .token.variable {
  color: #a67f59;
  background: #ffffff80;
}

.token.atrule, .token.attr-value, .token.keyword, .token.class-name {
  color: #98c379;
}

.token.regex, .token.important {
  color: #e90;
}

.language-css .token.string, .style .token.string {
  color: #a67f59;
  background: #ffffff80;
}

.token.important {
  font-weight: normal;
}

.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.namespace {
  opacity: .7;
}

@media screen and (width <= 767px) {
  pre[class*="language-"]:before, pre.language:before, pre[class*="language-"]:after, pre.language:after {
    box-shadow: none;
    bottom: 14px;
  }
}

.token.tab:not(:empty):before, .token.cr:before, .token.lf:before {
  color: #e0d7d1;
}

pre[class*="language-"].line-numbers.line-numbers, pre.line-numbers.line-numbers.language {
  padding-left: 0;
}

pre[class*="language-"].line-numbers.line-numbers code, pre.line-numbers.line-numbers.language code {
  padding-left: 3.8em;
}

pre[class*="language-"].line-numbers.line-numbers .line-numbers-rows, pre.line-numbers.line-numbers.language .line-numbers-rows {
  left: 0;
}

pre[class*="language-"][data-line], pre[data-line].language {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

pre[data-line] code {
  padding-left: 4em;
  position: relative;
}

pre .line-highlight {
  margin-top: 0;
}

code, code.language {
  color: #607d8b;
}

code .method {
  color: #98c379;
}

code .param-name {
  color: #d19a66;
}

code .param-value {
  color: #e06c75;
}

.progress {
  background: #efefef;
  border-radius: 20px;
  height: 12px;
  overflow: hidden;
}

.progress > div {
  --percentage: 0;
  width: calc(var(--percentage) * 1%);
  background-color: hsl(calc(120 - var(--percentage) / 100 * 120), 38%, 62%);
  border-radius: 4px;
  height: 100%;
  transition: width 1.2s, background-color 1.2s;
}

.progress ~ small {
  text-align: right;
  display: block;
}

.providers .provider {
  cursor: default;
  display: block;
}

.providers .provider .fa-check {
  color: #8bc34a;
}

.providers .provider .fa-plus {
  color: #00bcd4;
}

.providers .provider span, .providers .provider a {
  padding-left: 8px;
}

.providers .provider a:link {
  text-decoration: underline;
}

@media screen and (width >= 640px) {
  .providers .provider {
    width: 50%;
  }
}

@media screen and (width >= 1024px) {
  .providers .provider {
    width: 33.3333%;
  }
}

@media screen and (width >= 1280px) {
  .providers .provider {
    width: 25%;
  }
}

@media screen and (width >= 1024px) {
  .site-app--input {
    width: 40%;
  }
}

.site-app--output {
  grid-template-columns: 1fr 5fr;
}

@media screen and (width >= 1024px) {
  .site-app--output {
    width: 60%;
  }
}

.site-app--output .output-frame {
  border-radius: 0 0 .3em .3em;
  overflow: hidden;
}

.site-app--output .output-frame > div {
  color: #37474f;
}

.site-app--output .output-frame > div.preview {
  text-align: center;
  font-size: 0;
  line-height: 0;
  position: relative;
}

.site-app--output .output-frame > div.preview img {
  width: auto;
  max-height: 500px;
}

.site-app--output .output-frame > div.markdown {
  background: #fff;
  margin-top: 2px;
  display: block;
}

.site-app--output .output-frame > div.markdown > pre {
  margin: 0;
}

.site-app--output .output-frame > div.markdown > pre > code {
  border-radius: unset;
  border: none;
}

@media screen and (width <= 1023px) {
  .site-app--output .output-frame {
    border-radius: unset;
    width: 100vw;
    margin: 0 -1.5em -2.5em;
  }
}

form {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

form label {
  color: #fff;
  letter-spacing: 1px;
  width: 100%;
  margin-top: 15px;
  font-weight: bold;
  display: block;
  position: relative;
}

form label .input[type="checkbox"] {
  margin-right: 10px;
}

form label .input[type="text"], form label .input[type="url"] {
  color: initial;
  width: 100%;
  height: 40px;
  margin: 0 0 15px;
}

form label .input[type="text"][name="url"], form label .input[type="url"][name="url"] {
  padding-right: 40px;
}

form label .input[type="text"][name="url"] ~ img, form label .input[type="url"][name="url"] ~ img {
  border-left: 1px solid #42474b;
  width: 40px;
  height: 40px;
  padding: 9px;
  position: absolute;
  bottom: 15px;
  right: 0;
}

.buttons {
  text-align: center;
}

.buttons .button {
  margin: 30px 0;
}

.donations {
  background: #f6f3f4;
  border-bottom: 1px solid #ef9a9a;
}

.donations > div {
  z-index: 1;
  background: #f6f3f4;
  border-radius: 3px;
  padding: 8px 20px;
  position: relative;
}

.donations .close {
  box-sizing: content-box;
  opacity: 0;
  pointer-events: none;
  width: 24px;
  height: 24px;
  padding: 16px;
  transition: opacity .5s;
  position: absolute;
  top: 16px;
  right: 16px;
}

.donations .close svg {
  pointer-events: none;
  fill: #37474f;
  width: 100%;
  height: 100%;
}

.donations a {
  white-space: nowrap;
  color: #0288d1;
}

.donations a.beer img, .donations a.sponsor img, .donations a.host img {
  vertical-align: text-bottom;
  width: auto;
  height: 20px;
  margin: 0 1px 1px;
}

.donations a.beer {
  color: #f85d5d;
}

.donations a.sponsor {
  color: #db61a2;
}

.donations a.sponsor svg {
  fill: #db61a2;
}

.donations a.star {
  color: #f9a825;
}

.donations a.star svg {
  fill: #f9a825;
}

.donations a.host {
  color: #14bbab;
}

.donations a svg {
  vertical-align: text-top;
}

body:before {
  content: "";
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  background: #42474b;
  width: 100%;
  height: 100%;
  transition: opacity .5s;
  position: fixed;
  top: 0;
  left: 0;
}

body.modal {
  overflow: hidden;
}

body.modal .container.donations {
  max-height: 100vh;
  overflow: auto;
}

body.modal .container.donations .close {
  opacity: 1;
  pointer-events: all;
}

body.modal:before {
  opacity: .7;
  pointer-events: all;
}
/*# sourceMappingURL=repo.012a24a5.css.map */
